*{
    margin: 0;
    padding: 0;
}

body {
    box-sizing: border-box;
}

html {
    font-size: 100%; /* 1REM = 16px */
}

@media (max-width: 1200px) {
    html {
        font-size: 92%; /* 1REM = 14.72px */
    }
}

@media (max-width: 1100px) {
    html {
        font-size: 86%; /* 1REM = 14.4px */
    }
}

@media (max-width: 1000px) {
    html {
        font-size: 82%; /* 1REM = 13.12px */
    }
}

@media (max-width: 960px) {
    html {
        font-size: 82%; /* 1REM = 13.12px */
    }
    .block_respuestas {
        flex-direction: column;
    }
    .box_rta-margin {
        margin-right: 0!important;
        width: 20rem;
    }
    .box_rta-margin:not(:first-of-type) {
        margin-top: 1.5rem;
    }
}

@media (max-width: 960px) {
    html {
        font-size: 72%; /* 1REM = 11.52px */
    }
}

@media (max-width: 480px) {
    html {
        font-size: 62%; /* 1REM = 9.92px */
    }
}

